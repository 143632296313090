export const SHOW = "show";
export const HIDE = "hide";
export const SET_RATING = "set_rating";
export const SET_COMMENT = "set_comment";
export const RESET = "reset";
export const SUBMIT = "submit";

export const actionTypes = {
	SHOW,
	HIDE,
	SET_RATING,
	SET_COMMENT,
	RESET,
	SUBMIT,
};

const initialState = {
	show: null,
	current: null,
};

const resourceFeedback = (state = initialState, action) => {
	const {
		type,
		resourceId,
		ratingId,
		rating,
		value,
		comment,
		didSubmit,
		token,
	} = action;

	// console.log(state, action);

	switch (type) {
		case SHOW:
			return {
				...state,
				show: resourceId,
				current: {
					step: 0,
					resourceId,
				},
				// [resourceId]: {
				// 	step: 0,
				// },
			};
		case HIDE:
			return {
				...state,
				show: null,
				current: null,
			};
		case SET_RATING:
			return {
				...state,
				current: {
					...state.current,
					step: 1,
					resourceId,
					ratingId,
					rating,
					value,
				},
				// [resourceId]: {
				// 	step: 1,
				// 	resourceId,
				// 	ratingId,
				// 	rating,
				// 	value,
				// },
			};
		case SET_COMMENT:
			return {
				...state,
				current: {
					...state.current,
					comment,
				},
				// [resourceId]: {
				// 	...state[resourceId],
				// 	comment,
				// },
			};
		case SUBMIT:
			return {
				...state,
				current: {
					...state.current,
					didSubmit,
					step: 2,
					token,
				},
				// [resourceId]: {
				// 	...state[resourceId],
				// 	didSubmit,
				// 	step: 2,
				// 	token,
				// },
			};
		case RESET:
			return initialState;
		default:
			return state;
	}
};

export default resourceFeedback;
