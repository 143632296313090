import React, { useState } from "react";
import { ThemePurple } from "./theme-purple";
import { ThemeBlue } from "./theme-blue";
import { ThemePink } from "./theme-pink";
import { ThemeGreen } from "./theme-green";

export const ThemeContext = React.createContext();

export const Themes = {
	purple: ThemePurple,
	blue: ThemeBlue,
	pink: ThemePink,
	green: ThemeGreen,
};

export const ThemeProvider = ({ children }) => {
	const [theme, setTheme] = useState(Themes.purple);
	const [audioPlaying, setAudioPlaying] = useState(false);
	const [audioData, setAudioData] = useState(null);
	const [bgPlaying, setBgPlaying] = useState(false);

	const toggleTheme = (category) => {
		switch (category.toLowerCase()) {
			case "featured":
				setTheme(Themes.purple);
				break;
			case "distraction":
				setTheme(Themes.blue);
				break;
			case "connection":
				setTheme(Themes.pink);
				break;
			case "coping tools":
				setTheme(Themes.green);
				break;
			case "caring contacts":
				setTheme(Themes.purple);
				break;
			case "archived resources":
				setTheme(Themes.blue);
				break;
			default:
				setTheme(Themes.purple);
				break;
		}
	};

	const toggleAudio = (isPlaying) => {
		setAudioPlaying(isPlaying);
	};

	const toggleBackground = (isPlaying) => {
		setBgPlaying(isPlaying);
	};

	const loadData = (data) => {
		if (audioData) {
			return;
		}
		// console.log(data);
		setAudioData(data);
	};

	return (
		<ThemeContext.Provider
			value={{
				theme,
				toggleTheme,
				audioPlaying,
				toggleAudio,
				audioData,
				loadData,
				bgPlaying,
				toggleBackground,
			}}
		>
			{children}
		</ThemeContext.Provider>
	);
};
