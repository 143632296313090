import tw, { css } from "twin.macro";
import purpleBacking from "../../assets/resource-thumbnail-backing-purple.svg";
import iconPhonePurple from "../../assets/icon-phone-purple.svg";
import iconChatPurple from "../../assets/icon-chat-purple.svg";
import iconTextPurple from "../../assets/icon-text-purple.svg";
import iconAudioOnPurple from "../../assets/icon-audio-on-purple.svg";
import iconAudioOffPurple from "../../assets/icon-audio-off-purple.svg";
import iconFeedbackPurple from "../../assets/icon-feedback-purple.svg";

import iconNothelpful from "../../assets/feedback/icon-nothelpful-purple.svg";
import iconNeitherhelpfulnorunhelpful from "../../assets/feedback/icon-neither-purple.svg";
import iconHelpful from "../../assets/feedback/icon-helpful-purple.svg";

import iconNothelpfulHover from "../../assets/feedback/icon-nothelpful-hover.svg";
import iconNeitherhelpfulnorunhelpfulHover from "../../assets/feedback/icon-neither-hover.svg";
import iconHelpfulHover from "../../assets/feedback/icon-helpful-hover.svg";

export const ThemePurple = {
	textColor: tw`text-purple`,
	bgColor: tw`bg-purple`,
	textHoverColor: tw`hover:text-purple`,
	buttonLight: tw`bg-gradient-to-r from-purple-gradient-light1 to-purple-gradient-light2`,
	headerGradient: tw`bg-gradient-to-r  from-purple-header-gradient1 to-purple-header-gradient2`,
	bgColorA: [221.0 / 255.0, 249.0 / 255.0, 255.0 / 255.0, 1.0],
	bgColorB: [194.0 / 255.0, 186.0 / 255.0, 255.0 / 255.0, 1.0],
	inputBorder: tw`border-purple-light`,
	buttonDarkBorder: css`
		&:before {
			${tw`bg-gradient-to-r from-purple-gradient-dark1 to-purple-gradient-dark2`};
		}
	`,
	categoryColor: tw`bg-gradient-to-b from-purple-gradient-dark1 to-purple-gradient-dark2`,
	buttonLighter: tw`bg-gradient-to-b from-purple-gradient-lighter1 to-purple-gradient-lighter2`,
	thumbnailBackingRight: purpleBacking,
	thumbnailBackingLeft: tw`bg-gradient-to-br from-purple-gradient-thumbnail-left1 to-purple-gradient-thumbnail-left2`,
	iconPhone: iconPhonePurple,
	iconChat: iconChatPurple,
	iconText: iconTextPurple,
	iconAudioOn: iconAudioOnPurple,
	iconAudioOff: iconAudioOffPurple,
	iconFeedback: iconFeedbackPurple,
	iconNothelpful: iconNothelpful,
	iconNeitherhelpfulnorunhelpful: iconNeitherhelpfulnorunhelpful,
	iconHelpful: iconHelpful,
	iconNothelpfulHover: iconNothelpfulHover,
	iconNeitherhelpfulnorunhelpfulHover: iconNeitherhelpfulnorunhelpfulHover,
	iconHelpfulHover: iconHelpfulHover,
	iconHoverGradient: tw`hover:bg-gradient-to-b hover:from-purple-gradient-dark1 hover:to-purple-gradient-dark2`,
	buttonBorder: tw`border-purple`,
};
