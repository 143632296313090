import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Provider } from "react-redux";
import Background from "./src/components/dynamic-components/background";
import AudioPlayer from "./src/components/dynamic-components/audio-player";
import Layout from "./src/components/layout";
import { ThemeProvider } from "./src/components/themes/theme-context";

import createStore from "./src/store/createStore";

const Wrapper = ({ element }) => {
	const store = createStore();

	return (
		<Layout>
			<ThemeProvider>
				<Background />
				<AudioPlayer />
				<GoogleReCaptchaProvider
					reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
					scriptProps={{ async: true, defer: true }}
				>
					<Provider store={store}>{element}</Provider>
				</GoogleReCaptchaProvider>
			</ThemeProvider>
		</Layout>
	);
};

export default Wrapper;
