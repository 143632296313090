import tw, { css } from "twin.macro";
import greenBacking from "../../assets/resource-thumbnail-backing-green.svg";
import iconPhoneGreen from "../../assets/icon-phone-green.svg";
import iconChatGreen from "../../assets/icon-chat-green.svg";
import iconTextGreen from "../../assets/icon-text-green.svg";
import iconAudioOnGreen from "../../assets/icon-audio-on-green.svg";
import iconAudioOffGreen from "../../assets/icon-audio-off-green.svg";
import iconFeedbackGreen from "../../assets/icon-feedback-green.svg";

import iconNothelpful from "../../assets/feedback/icon-nothelpful-green.svg";
import iconNeitherhelpfulnorunhelpful from "../../assets/feedback/icon-neither-green.svg";
import iconHelpful from "../../assets/feedback/icon-helpful-green.svg";

import iconNothelpfulHover from "../../assets/feedback/icon-nothelpful-hover.svg";
import iconNeitherhelpfulnorunhelpfulHover from "../../assets/feedback/icon-neither-hover.svg";
import iconHelpfulHover from "../../assets/feedback/icon-helpful-hover.svg";

export const ThemeGreen = {
	textColor: tw`text-green`,
	bgColor: tw`bg-green`,
	textHoverColor: tw`hover:text-green`,
	buttonLight: tw`bg-gradient-to-r from-green-gradient-light1 to-green-gradient-light2`,
	headerGradient: tw`bg-gradient-to-r  from-green-header-gradient1 to-green-header-gradient2`,
	bgColorA: [252.0 / 255.0, 255.0 / 255.0, 218.0 / 255.0, 1.0],
	bgColorB: [189.0 / 255.0, 249.0 / 255.0, 207.0 / 255.0, 1.0],
	inputBorder: tw`border-green-light`,
	buttonDarkBorder: css`
		&:before {
			${tw`bg-gradient-to-r from-green-gradient-dark1 to-green-gradient-dark2`};
		}
	`,
	categoryColor: tw`bg-gradient-to-b from-green-gradient-dark1 to-green-gradient-dark2`,
	buttonLighter: tw`bg-gradient-to-b from-green-gradient-lighter1 to-green-gradient-lighter2`,
	thumbnailBackingRight: greenBacking,
	thumbnailBackingLeft: tw`bg-gradient-to-br from-green-gradient-thumbnail-left1 to-green-gradient-thumbnail-left2`,
	iconPhone: iconPhoneGreen,
	iconChat: iconChatGreen,
	iconText: iconTextGreen,
	iconAudioOn: iconAudioOnGreen,
	iconAudioOff: iconAudioOffGreen,
	iconFeedback: iconFeedbackGreen,
	iconNothelpful: iconNothelpful,
	iconNeitherhelpfulnorunhelpful: iconNeitherhelpfulnorunhelpful,
	iconHelpful: iconHelpful,
	iconNothelpfulHover: iconNothelpfulHover,
	iconNeitherhelpfulnorunhelpfulHover: iconNeitherhelpfulnorunhelpfulHover,
	iconHelpfulHover: iconHelpfulHover,
	iconHoverGradient: tw`hover:bg-gradient-to-b hover:from-green-gradient-dark1 hover:to-green-gradient-dark2`,
	buttonBorder: tw`border-green`,
};
