import React from "react";
import loadable from "@loadable/component";

const AudioPlayerProxy = loadable(() =>import("../bottom-navigation/audio-player"));

const AudioPlayer = () => {
	return <AudioPlayerProxy />;
};

export default AudioPlayer;
