import tw, { css } from "twin.macro";
import blueBacking from "../../assets/resource-thumbnail-backing-blue.svg";
import iconChatBlue from "../../assets/icon-chat-blue.svg";
import iconPhoneBlue from "../../assets/icon-phone-blue.svg";
import iconTextBlue from "../../assets/icon-text-blue.svg";
import iconAudioOnBlue from "../../assets/icon-audio-on-blue.svg";
import iconAudioOffBlue from "../../assets/icon-audio-off-blue.svg";
import iconFeedbackBlue from "../../assets/icon-feedback-blue.svg";

import iconNothelpful from "../../assets/feedback/icon-nothelpful-blue.svg";
import iconNeitherhelpfulnorunhelpful from "../../assets/feedback/icon-neither-blue.svg";
import iconHelpful from "../../assets/feedback/icon-helpful-blue.svg";

import iconNothelpfulHover from "../../assets/feedback/icon-nothelpful-hover.svg";
import iconNeitherhelpfulnorunhelpfulHover from "../../assets/feedback/icon-neither-hover.svg";
import iconHelpfulHover from "../../assets/feedback/icon-helpful-hover.svg";

export const ThemeBlue = {
	textColor: tw`text-blue`,
	bgColor: tw`bg-blue`,
	textHoverColor: tw`hover:text-blue`,
	buttonLight: tw`bg-gradient-to-r from-blue-gradient-light1 to-blue-gradient-light2`,
	headerGradient: tw`bg-gradient-to-r  from-blue-header-gradient1 to-blue-header-gradient2`,
	bgColorA: [236.0 / 255.0, 255.0 / 255.0, 245.0 / 255.0, 1.0],
	bgColorB: [164.0 / 255.0, 223.0 / 255.0, 253.0 / 255.0, 1.0],
	inputBorder: tw`border-blue-light`,
	buttonDarkBorder: css`
		&:before {
			${tw`bg-gradient-to-r from-blue-gradient-dark1 to-blue-gradient-dark2`};
		}
	`,
	categoryColor: tw`bg-gradient-to-b from-blue-gradient-dark1 to-blue-gradient-dark2`,
	buttonLighter: tw`bg-gradient-to-b from-blue-gradient-lighter1 to-blue-gradient-lighter2`,
	thumbnailBackingRight: blueBacking,
	thumbnailBackingLeft: tw`bg-gradient-to-br from-blue-gradient-thumbnail-left1 to-blue-gradient-thumbnail-left2`,
	iconPhone: iconPhoneBlue,
	iconChat: iconChatBlue,
	iconText: iconTextBlue,
	iconAudioOn: iconAudioOnBlue,
	iconAudioOff: iconAudioOffBlue,
	iconFeedback: iconFeedbackBlue,
	iconNothelpful: iconNothelpful,
	iconNeitherhelpfulnorunhelpful: iconNeitherhelpfulnorunhelpful,
	iconHelpful: iconHelpful,
	iconNothelpfulHover: iconNothelpfulHover,
	iconNeitherhelpfulnorunhelpfulHover: iconNeitherhelpfulnorunhelpfulHover,
	iconHelpfulHover: iconHelpfulHover,
	iconHoverGradient: tw`hover:bg-gradient-to-b hover:from-blue-gradient-dark1 hover:to-blue-gradient-dark2`,
	buttonBorder: tw`border-blue`,
};
