import React from "react";
import loadable from "@loadable/component";

const BackgroundProxy = loadable(() => import("../background"));

const Background = () => {
	return <BackgroundProxy />;
};

export default Background;
