export const SHOW_SHARING = "show_sharing";
export const HIDE_SHARING = "hide_sharing";

export const actionTypes = {
	SHOW_SHARING,
	HIDE_SHARING,
};

const initialState = {
	show: null,
};

const sharing = (state = initialState, action) => {
	const { type, resourceId } = action;

	switch (type) {
		case SHOW_SHARING:
			return {
				...state,
				show: resourceId,
			};
		case HIDE_SHARING:
			return {
				...state,
				show: null,
			};
		default:
			return state;
	}
};

export default sharing;
