import React from "react";
import "@fontsource/dm-sans";
import "@fontsource/noto-serif";
import tw from "twin.macro";
import { m, LazyMotion } from "framer-motion";

const PageContainer = tw.div`font-sans font-normal text-base leading-sans-base min-w-screen`;

const loadFeatures = () =>
	import("../components/dynamic-components/features").then(
		(res) => res.default
	);

const Layout = ({ children }) => {
	// return <PageContainer>{children}</PageContainer>;

	const fadeAnimation = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
	};

	return (
		<LazyMotion features={loadFeatures}>
			<m.div
				initial="hidden"
				animate="visible"
				variants={fadeAnimation}
				transition={{ duration: 0.8 }}
			>
				<PageContainer>{children}</PageContainer>
			</m.div>
		</LazyMotion>
	);
};

export default Layout;
