export const SORT_TYPE = "sort_type";

export const actionTypes = {
	SORT_TYPE,
};

const initialState = {
	selectedCategory: null,
};

const resources = (state = initialState, action) => {
	const { type, category } = action;
	switch (type) {
		case SORT_TYPE:
			return {
				...state,
				selectedCategory: category,
			};

		default:
			return state;
	}
};

export default resources;
