import tw, { css } from "twin.macro";
import pinkBacking from "../../assets/resource-thumbnail-backing-pink.svg";
import iconPhonePink from "../../assets/icon-phone-pink.svg";
import iconChatPink from "../../assets/icon-chat-pink.svg";
import iconTextPink from "../../assets/icon-text-pink.svg";
import iconAudioOnPink from "../../assets/icon-audio-on-pink.svg";
import iconAudioOffPink from "../../assets/icon-audio-off-pink.svg";
import iconFeedbackPink from "../../assets/icon-feedback-pink.svg";

import iconNothelpful from "../../assets/feedback/icon-nothelpful-pink.svg";
import iconNeitherhelpfulnorunhelpful from "../../assets/feedback/icon-neither-pink.svg";
import iconHelpful from "../../assets/feedback/icon-helpful-pink.svg";

import iconNothelpfulHover from "../../assets/feedback/icon-nothelpful-hover.svg";
import iconNeitherhelpfulnorunhelpfulHover from "../../assets/feedback/icon-neither-hover.svg";
import iconHelpfulHover from "../../assets/feedback/icon-helpful-hover.svg";

export const ThemePink = {
	textColor: tw`text-pink`,
	bgColor: tw`bg-pink`,
	textHoverColor: tw`hover:text-pink`,
	buttonLight: tw`bg-gradient-to-r from-pink-gradient-light1 to-pink-gradient-light2`,
	headerGradient: tw`bg-gradient-to-r  from-pink-header-gradient1 to-pink-header-gradient2`,
	bgColorA: [255.0 / 255.0, 251.0 / 255.0, 237.0 / 255.0, 1.0],
	bgColorB: [255.0 / 255.0, 141.0 / 255.0, 214.0 / 255.0, 1.0],
	inputBorder: tw`border-pink-light`,
	buttonDarkBorder: css`
		&:before {
			${tw`bg-gradient-to-r from-pink-gradient-dark1 to-pink-gradient-dark2`};
		}
	`,
	categoryColor: tw`bg-gradient-to-b from-pink-gradient-dark1 to-pink-gradient-dark2`,
	buttonLighter: tw`bg-gradient-to-b from-pink-gradient-lighter1 to-pink-gradient-lighter2`,
	thumbnailBackingRight: pinkBacking,
	thumbnailBackingLeft: tw`bg-gradient-to-br from-pink-gradient-thumbnail-left1 to-pink-gradient-thumbnail-left2`,
	iconPhone: iconPhonePink,
	iconChat: iconChatPink,
	iconText: iconTextPink,
	iconAudioOn: iconAudioOnPink,
	iconAudioOff: iconAudioOffPink,
	iconFeedback: iconFeedbackPink,
	iconNothelpful: iconNothelpful,
	iconNeitherhelpfulnorunhelpful: iconNeitherhelpfulnorunhelpful,
	iconHelpful: iconHelpful,
	iconNothelpfulHover: iconNothelpfulHover,
	iconNeitherhelpfulnorunhelpfulHover: iconNeitherhelpfulnorunhelpfulHover,
	iconHelpfulHover: iconHelpfulHover,
	iconHoverGradient: tw`hover:bg-gradient-to-b hover:from-pink-gradient-dark1 hover:to-pink-gradient-dark2`,
	buttonBorder: tw`border-pink`,
};
